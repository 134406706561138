<template>
  <v-container class="list-timesheet-container">
    <list-timesheet-header
      :draft="draft"
      :timesheets="timesheets"
      @fetchTimesheets="fetchTimesheetsList"
    />

    <timesheet-list
      v-if="show"
      :page="getPage"
      :timesheets="timesheets.data"
      :draft-timesheet="draft"
      @deleteTimesheet="deleteOneTimesheet"
      @editTimesheet="editOneTimesheet"
    />

    <v-row
      v-if="show"
      justify="end"
    >
      <pagination
        :pagination-settings="timesheets.pagination_settings"
        :page="getPage"
        @changed="changePage"
      />
    </v-row>

    <placeholder
      v-else
      :message="$t('components.lists.timesheets.empty')"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Placeholder from '@/components/lists/Placeholder.vue';
import ListTimesheetHeader from '@/components/headers/ListTimesheetHeader.vue';
import TimesheetList from '@/components/lists/TimesheetList.vue';
import Pagination from '@/components/pagination/Pagination.vue';

export default {
  name: 'ListTimesheet',
  components: {
    ListTimesheetHeader,
    TimesheetList,
    Pagination,
    Placeholder,
  },
  props: {
    draft: {
      type: Boolean,
      default: false,
    },
    timesheets: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    dates: null,
    show: false,
    params: {},
  }),
  computed: {
    ...mapGetters([
      'getPage',
    ]),
  },
  watch: {
    timesheets(value) {
      if (value.data.length !== 0) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  methods: {
    ...mapActions([
      'fetchTimesheets',
      'deleteTimesheet',
      'showTimesheet',
      'updatePage',
    ]),
    getTimesheets(draft) {
      if (draft) {
        this.fetchTimesheets({ by_status: 'open' });
      } else {
        this.fetchTimesheets({ by_status: 'closed' });
      }
    },
    fetchTimesheetsList(params) {
      const paramsObj = {
        by_status: params.status,
        by_date_init: params.date_init,
        by_date_end: params.date_end,
      };

      this.params = params;
      this.fetchTimesheets(paramsObj);
      this.updatePage(1);
    },
    changePage() {
      if (this.$route.name === 'closedTimesheet') {
        this.params.by_status = 'closed';
      } else if (this.$route.name === 'draftTimesheet') {
        this.params.by_status = 'open';
      }
      this.params.page = this.getPage;
      this.fetchTimesheets(this.params);
    },
    deleteOneTimesheet(params) {
      this.deleteTimesheet(params);
      this.updatePage(1);
    },
    editOneTimesheet(params) {
      this.showTimesheet(params);
      this.$router.push({ name: 'timesheet', params });
    },
  },
};
</script>

<style lang="scss">
.list-timesheet-container {
  background: $color_background;
}
</style>
