<template>
  <v-container class="generate-header">
    <v-row
      class="content"
    >
      <v-col
        class="px-2 d-flex"
        cols="12"
        sm="3"
      >
        <date-range @datesSelected="saveDatesAndApplyFilters" />
      </v-col>
      <v-col
        cols="9"
        sm="6"
        class="px-2"
      >
        <v-select
          item-text="name"
          item-value="id"
          label="Turnos"
          color="accent"
          dense
          height="42"
          outlined
        />
      </v-col>
      <v-col
        cols="3"
        sm="3"
        class="px-2"
      >
        <v-btn
          color="accent"
          class="ml-6 button pa-5"
          @click="fetchTimesheets"
        >
          {{ text('fetch') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="!draft"
      align="center"
    >
    <!-- Não está exercendo nenhuma funcionalidade no momento (checkbox)-->
      <!-- <v-checkbox
        class="pa-1"
        color="accent"
        :label="`${ text('include_warning') }`"
      /> -->
    </v-row>
  </v-container>
</template>

<script>
import formats from '@/mixins/formats';
import DateRange from '@/components/forms/calendar/DateRange.vue';

export default {
  name: 'ListTimesheetHeader',
  components: {
    DateRange,
  },
  mixins: [formats],
  props: {
    timesheets: {
      type: Object,
      default: () => {},
    },
    draft: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dates: [],
    status: '',
  }),
  methods: {
    saveDatesAndApplyFilters(dates) {
      this.dates = dates;
    },
    text(label) {
      return this.$t(`components.header.mirrors.${label}`);
    },
    fetchTimesheets() {
      if (this.dates[0] && this.dates[1]) {
        this.$emit('fetchTimesheets',
          {
            status: this.draft ? 'open' : 'closed',
            date_init: this.dates[0],
            date_end: this.dates[1],
          });
      }
    },
  },
};
</script>

<style lang="sccs" src="@/assets/scss/components/headers/generate_timesheet.scss" scoped />
