<template>
  <v-container class="draft-timesheet-container">
    <timesheet-tabs />

    <list-timesheet
      :draft="false"
      :timesheets="getTimesheets"
    />
  </v-container>
</template>

<script>
import formats from '@/mixins/formats';
import { mapGetters, mapActions } from 'vuex';
import ListTimesheet from '@/components/timesheets/ListTimesheet.vue';
import TimesheetTabs from '../../components/tabs/TimesheetTabs.vue';

export default {
  name: 'ClosedTimesheet',
  components: {
    ListTimesheet,
    TimesheetTabs,
  },
  mixins: [formats],
  data: () => ({
    context: 'closedTimesheet',
  }),
  computed: {
    ...mapGetters([
      'getTimesheets',
      'getDateRange',
      'getPage',
      'getComponentFatherDate',
    ]),
  },
  created() {
    if (!this.getComponentFatherDate || this.getComponentFatherDate !== this.context) {
      this.saveDateFatherComponent(this.context);
      this.saveDateRange(this.currentMonth());
    }
  },
  mounted() {
    this.fetchTimesheets({ by_status: 'closed' });
  },
  methods: {
    ...mapActions([
      'fetchTimesheets',
      'saveDateFatherComponent',
      'saveDateRange',
    ]),
  },
};
</script>

<style lang="scss">
.draft-timesheet-container {
  background: $color_background;
}
</style>
