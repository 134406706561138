<template>
  <div style="border-bottom: 2px solid #BBB; height: 36px;">
    <v-btn
      tile
      text
      :class="defineActiveGenerate()"
      @click="goTo('timesheet')"
    >
      {{ text('generate') }}
    </v-btn>

    <v-btn
      tile
      text
      :class="defineActiveDraft()"
      @click="goTo('draftTimesheet')"
    >
      {{ text('draft') }}
    </v-btn>

    <v-btn
      tile
      text
      :class="defineActiveClosed()"
      @click="goTo('closedTimesheet')"
    >
      {{ text('ready') }}
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TimesheetTabs',
  methods: {
    ...mapActions([
      'updatePage',
    ]),
    text(label) {
      return this.$t(`views.timesheet.tabs.${label}`);
    },
    defineActiveGenerate() {
      let buttonClass = 'tab-button generate';

      if (this.$route.name === 'timesheet') {
        buttonClass = `${buttonClass} active`;
      }

      return buttonClass;
    },

    defineActiveDraft() {
      let buttonClass = 'tab-button draft';

      if (this.$route.name === 'draftTimesheet') {
        buttonClass = `${buttonClass} active`;
      }

      return buttonClass;
    },

    defineActiveClosed() {
      let buttonClass = 'tab-button closed';

      if (this.$route.name === 'closedTimesheet') {
        buttonClass = `${buttonClass} active`;
      }

      return buttonClass;
    },

    goTo(item) {
      if (this.$route.name !== item) {
        this.updatePage(1);
        this.$router.push({
          name: item,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.tab-button {
  box-shadow: none;
  text-transform: none;
  background: $color_background;
  border-bottom: 2px solid #BBB;
  font-family: 'Acumin-Pro-Bold';
  font-size: 16px !important; // overwriting vuetify v-btn
  color: $color_login_info !important; // overwriting vuetify v-btn

  &.generate {
    width: 192px;
  }

  &.draft {
    width: 116px;
  }

  &.closed {
    width: 130px;
  }

  &.active {
    color: $color_list_body !important;
    border-bottom: 2px solid $color_primary;
  }
}
</style>
