<template>
  <v-simple-table class="jornada-list">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-center">
            {{ text("code") }}
          </th>
          <th class="text-center">
            {{ text("period") }}
          </th>
          <th class="text-center">
            {{ text("business_unit") }}
          </th>
          <th class="text-center">
            {{ text("branch_office") }}
          </th>
          <th class="text-center">
            {{ text("work_period") }}
          </th>
          <th
            v-if="!draftTimesheet"
            class="text-center"
          >
            {{ text("print") }}
          </th>
          <th
            v-else
            class="text-center"
          />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="timesheet in timesheets"
          :key="timesheet.id"
          class="list-row"
        >
          <td class="text-center">
            {{ timesheet.id }}
          </td>
          <td class="text-center">
            {{ formatDate(timesheet.date_init) }} - {{ formatDate(timesheet.date_end) }}
          </td>
          <td class="text-center">
            {{ timesheet.business_unit_name }}
          </td>
          <td class="text-center">
            {{ timesheet.branch_name }}
          </td>
          <td class="text-center">
            {{ text("standard_shift") }}
          </td>
          <td
            v-if="!draftTimesheet"
            class="text-center"
          >
            <v-btn
              icon
              color="accent"
              small
              @click="print(timesheet)"
            >
              <v-icon
                dark
              >
                mdi-printer
              </v-icon>
            </v-btn>
          </td>
          <td
            v-else
            class="text-center"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="spacing"
                  color="accent"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteTimesheet(timesheet.id)"
                >
                  mdi-close-circle
                </v-icon>
              </template>
              <span>{{ $t('components.lists.timesheets.tooltips.delete') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="spacing"
                  color="accent"
                  v-bind="attrs"
                  v-on="on"
                  @click="editTimesheet(timesheet)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t('components.lists.timesheets.tooltips.edit') }}</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import formats from '@/mixins/formats';
import { mapActions } from 'vuex';

export default {
  name: 'TimesheetList',
  mixins: [formats],
  props: {
    timesheets: {
      type: Array,
      default: () => [],
    },
    draftTimesheet: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions([
      'draft',
      'initLoading',
    ]),
    text(label) {
      return this.$t(`components.lists.timesheets.${label}`);
    },
    formatDate(dateParam) {
      return this.formatDateISO(dateParam);
    },
    deleteTimesheet(id) {
      this.$emit('deleteTimesheet', { id });
    },
    editTimesheet(timesheet) {
      const params = {
        branch_id: timesheet.branch_id,
        unit_id: timesheet.business_unit_id,
        date_init: timesheet.date_init,
        date_end: timesheet.date_end,
        driver_profile_id: timesheet.driver_profile_id,
        cpf: timesheet.cpf,
      };
      this.initLoading();
      this.draft(params);
      this.$emit('editTimesheet', { id: timesheet.id });
    },
    print(params) {
      const routeData = this.$router.resolve({ name: 'Print', params: { timesheetId: params.id } });
      window.open(routeData.href, '_blank');
    },
  },

};
</script>

<style lang="scss" scoped>
.jornada-list {
  background-color: $color_background;
  font-family: 'Acumin-Pro-Regular';
  color: $color_list_body;

  .v-data-table__wrapper > table > thead > tr > th {
    font-weight: 400;
    color: $color_list_header;
  }

  .mirror {
    font-family: 'Acumin-Pro-Bold';
    width: 100px !important;
  }

  .list-row {
    padding: 24px 16px;
    height: 64px;

    .spacing {
      margin: 10px
    }

    .buttons {
      width: 120px;

      .icon {
        padding: 0 10px;
      }
    }
  }
}

</style>
